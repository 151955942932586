import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core'
import { green, grey, red } from '@material-ui/core/colors'
import IMFilterButton from './IMFilterButton'
import IMActionBar from './IMActionBar'
import StarData from '../Structure/DisplayData/StarData'
import IMSearchbar from './IMSearchbar'
import DisplayText from '../Texts/DisplayText'
import { onGetDateFormat, onGetTimeDuration, onGetYearCatalog } from '../../shared/utility'
import { fCurrency } from '../../utils/formatNumber'

const header = [
    {id:'subject', label:'Asunto'},
    {id:'date_hour', label:'Fecha'},
    {id:'from', label:'De'},
    {id:'to', label:'Para'},
    {id:'cc', label:'CC'},
]




const InboundMailsTable = props => {

    const classes = useStyles()
    const {
        loading, 
        data, 
        total, 
        filter_data,
        tableFilter,
        onDownload,
        onUpdateTableFilter,  
        hide_search,
        hide_month,
        onSelect,
     } = props

     const {
        search_by,
        year,
        month,
        month_options,
        onChangeFilter,
        onChangeSearch,
        setSearchBy
     } = filter_data

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} > 
                    <Grid item xs={12}>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item xs/>
                            {!hide_search && <>
                                <Grid item>
                                    <IMFilterButton
                                        label='Buscar por'
                                        selected={search_by}
                                        options={[
                                            {value:'business', label:'Razón social'},
                                        ]}
                                        onChange={(val) => setSearchBy(val)}
                                    />
                                </Grid>
                                <Grid item><IMSearchbar onRequestSearch={onChangeSearch}/></Grid>
                            </>}
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {

                                    //const report_date = onGetDateFormat(date, 'DD MMM YY')
                                    //const timdata = onGetTimeDuration(duration)

                                    const date = onGetDateFormat(item?.date ?? null, 'DD MMM YY')
                                    const hour = onGetDateFormat(item?.date ?? null, 'HH:mm:ss')

                                    const from_info = parseUserInfo(item?.from)
                                    const to_info = extractNamesAndEmails(item?.to)
                                    const cc_info = extractNamesAndEmails(item?.cc)

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} component='div' onClick={() => onSelect(item)} >
                                            <TableCell className={classes.cell} style={{minWidth:200}} ><DisplayText variant='subtitle2'>{item?.subject ?? '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} >
                                                <DisplayText variant='body2' noWrap >{date}</DisplayText>
                                                <DisplayText variant='body2' noWrap >{hour}</DisplayText>
                                            </TableCell>
                                            <TableCell className={classes.cell} >
                                                
                                                <DisplayText variant='caption' noWrap >{from_info?.name}</DisplayText>
                                                <DisplayText variant='subtitle2' noWrap >{from_info?.email}</DisplayText>
                                            </TableCell>
                                            <TableCell className={classes.cell} >
                                                {to_info.map(el => {
                                                    return(
                                                        <div>
                                                            <DisplayText variant='caption' noWrap >{el?.name}</DisplayText>
                                                            <DisplayText variant='subtitle2' noWrap >{el?.email}</DisplayText>
                                                        </div>
                                                    )
                                                })}
                                            </TableCell>
                                            <TableCell className={classes.cell} >
                                                {cc_info.map(el => {
                                                    return(
                                                        <div>
                                                            <DisplayText variant='caption' noWrap >{el?.name}</DisplayText>
                                                            <DisplayText variant='subtitle2' noWrap >{el?.email}</DisplayText>
                                                        </div>
                                                    )
                                                })}
                                            </TableCell>
                                            

                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <IMActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justifyContent='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src="/assets/images/empty.png" alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No se encontraron registros</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:8,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        padding:'6px 12px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    status_positive:{
        background:green[400],
        color:'white',
        borderRadius:32,
        textAlign:'center',
        padding:'2px 8px'
    },
    download_btn:{
        background: theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background: theme.palette.primary.dark
        }
    }
}))

export default InboundMailsTable

const parseUserInfo = (input) => {
    // Usa una expresión regular para extraer el nombre y el correo electrónico
    if(!input) return null

    const regex = /^(.+?)\s<(.+?)>$/;
    const match = input.match(regex);
  
    if (match) {
      return {
        name: match[1],
        email: match[2]
      };
    } else {
      throw new Error('El formato del string es incorrecto.');
    }
  };

  function extractNamesAndEmails(input) {

    if(!input) return []

    const parts = input.split(",")

    // Regex para capturar correos y nombres
    const regex = /^(.*)?\s*<([^>]+)>$/;
    let result = [];

    parts.forEach(part => {
        const trimmedPart = part.trim();
        const match = regex.exec(trimmedPart);
        if (match) {
            const name = match[1].trim();
            const email = match[2].trim();
            result.push({ name, email });
        }

    })

    return result;
}


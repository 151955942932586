import React, { useState, useEffect } from 'react';
import LoadingContainer from '../../../../../../components_v1/Structure/Layouts/LoadingContainer';
import TimeReportTable from '../../../../../../components_v1/Structure/Tables/TimeReportTable/TimeReportTable';
import queryString from 'query-string'
import { onGetMonthsCatalog } from '../../../../../../shared/utility';
import moment from 'moment'
import { request_timereports, request_timereport_statistic } from '../../GeneralView/requests';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root:{
        padding:'32px 0px'
    }
}))

const UserTimeReport = ({history, match}) => {

    const classes = useStyles()
    const [loading, setLoading] = useState()
    const [error, setError] = useState(null)
    const [timereports, setTimereports] = useState([])
    const [statistics, setStatistics] = useState(null)
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0})
    const [filter, setFilter] = useState({query:''})
    const [month, setMonth] = useState(moment().month()+1)
    const [year, setYear] = useState(moment().year())
    const [month_options, setMonthOptions] = useState(onGetMonthsCatalog(moment().year()))
    const [task_type, setTaskType] = useState(-1)

    const userID = match.params.id

    console.log(tableFilter)

    useEffect(() => {
        const initModule = async() => {
            //setSuccess('Bienvenido')
            setLoading(true)
            try {
                const {limit, offset} = tableFilter
                const rangeDate = month_options.find(el => el.value === month)
                let filterArgs = [
                    {"field":"user_id","operator":"LIKE","value":userID},
                    {"field":"date","operator":">=","value":rangeDate.start_date},
                    {"field":"date","operator":"<=","value":rangeDate.end_date}
                ]
                if(task_type !== -1) filterArgs.push({"field":"task_type_id","operator":"LIKE","value":task_type})

                const param2send = {language_id:2, limit:limit, offset:offset*limit, order_by:'id_time_report', order:1, start_date:rangeDate.start_date, end_date:rangeDate.end_date}

                console.log("Params2send", param2send)

                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                const params = `?${queryString.stringify(param2send)}&filter=${filterArgs}`
                const _timereports = await request_timereports(params)
                const statistics_params = {
                    language_id:2,
                    start_date: rangeDate.start_date,
                    end_date:rangeDate.end_date
                }
                const _statistics = await request_timereport_statistic(userID, statistics_params)
                setTotal(_timereports.count)
                setTimereports(_timereports.time_reports)
                setStatistics(_statistics && _statistics.length ? _statistics[0] : null)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }

        initModule()
    }, [filter, tableFilter, month, task_type])

    const onChangeFilter = (value, type) => {
        setTableFilter({
            ...tableFilter,
            offset:0
        })
        if(type === 'month') setMonth(value)
        if(type === 'year'){
            setYear(value)
            setMonthOptions(onGetMonthsCatalog(value.toString()))
            setMonth(1)
        }
        if(type === 'task_type') setTaskType(value)

    }

    const onSelectedItem = (item) => history.push(`/users/details/${userID}/timereport/${item.id_time_report}`)

    return ( 
        <div className={classes.root}>
            <LoadingContainer>
                <>
                    <TimeReportTable 
                        statistics={statistics ? statistics : {}}
                        task_type={task_type}
                        month={month} year={year} monthCatalog={month_options}
                        data={timereports} filter={filter} tableFilter={tableFilter}
                        total={total} loading={loading}
                        onSelectedItem={onSelectedItem}
                        onUpdateTableFilter={(data) => {
                            console.log("Data2update", data)
                            setTableFilter(data)
                        }}
                        onChangeFilter={onChangeFilter} />
                </>
            </LoadingContainer>
        </div>
        
     );
}
 
export default UserTimeReport;
import React, { useCallback, useEffect, useState } from 'react';
import SimpleModal from '../../components_v1/Structure/Modals/SimpleModal';
import useInboundMail from '../../hooks/api/useInboundMail';
import LoadingContainer from '../../components_v1/Structure/Layouts/LoadingContainer';
import { Box, Drawer, Grid, Icon, IconButton, Typography } from '@material-ui/core';
import { onGetDateFormat } from '../../shared/utility';
import { blueGrey } from '@material-ui/core/colors';
import {styled} from '@material-ui/styles'

const InboundMailModal = ({
    open, onClose, id_inbound_mail
}) => {
    
    const inboundMailManager = useInboundMail()
    const [loading, setLoading] = useState(false)
    const [mail, setMail] = useState(null)

    const initialize = useCallback(async()=>{
        setLoading(true)
        try {
            const _request = await inboundMailManager.onGet(id_inbound_mail)
            console.log(_request)
            setMail(_request)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    },[id_inbound_mail])

    useEffect(() => {
        if(open){
            initialize()
        }else{

        }
    },[open])

    return ( 
        <Drawer
            anchor='right'
            open={open}
            onClose={onClose}
        >
            <StyledContainer 
                
            >
                <LoadingContainer loading={loading}>
                    <div>
                        <Grid container>
                            <Grid item xs>
                                <Typography variant='h6' style={{marginBottom:16, fontWeight:600}}>Detalles del correo</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton size='small' onClick={onClose}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Typography variant='body2'><strong>Asunto:</strong> {mail?.subject}</Typography>
                        <Typography variant='body2'><strong>Fecha:</strong> {onGetDateFormat(mail?.date, 'DD-MM-YYYY, HH:mm:ss')}</Typography>
                        <Typography variant='body2'><strong>De:</strong> {mail?.from}</Typography>
                        <Typography variant='body2'><strong>Para:</strong> {mail?.to}</Typography>
                        <Typography variant='body2'><strong>CC:</strong> {mail?.cc}</Typography>
                        <div style={{
                            margin:'32px 0px',
                            padding:16,
                            background:blueGrey[50],
                            borderRadius:8
                        }}> 
                            <div style={{
                                scale:2,
                            }}  dangerouslySetInnerHTML={{ __html: mail?.html }} />
                        </div>
                    </div>
                </LoadingContainer>
            </StyledContainer>
        </Drawer>
     );
}
 
export default InboundMailModal;

const StyledContainer = styled('div')(({ theme }) => ({
    position:'relative',
    padding:16,
    width:600,
    [theme.breakpoints.down('sm')]: {
        width: 250, // Cambia el ancho para pantallas menores a 'sm'
    },

}));
import React from 'react'
import { Typography } from '@material-ui/core'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './richTextStyles.css'


const RichTextForm = props => {

    const {label, value, data, onChange, imgIcon, onKeyPress} = props

    const onInnerChange = (event, editor) => {

        const response = editor.getData()
        onChange(response);
    } 


    return(
        <div>
           <Typography variant='body2' style={{fontWeight:500}} >{label}</Typography>
            <div style={{position:'relative', height:'400px', marginTop:'8px', border:'1px solid rgba(0, 0, 0, 0.23)', borderRadius:'16px 6px 6px 16px'}}>
                {value !== null ? <CKEditor
                    
                    id={`rt-${label}`}
                    //className='ck-editor2' 
                    editor={ClassicEditor}
                    onError={() => console.log('Hubo un error en ckeditr')}
                    onReady={() => console.log('Estuvo listo')}
                    data={value}
                    onChange={onInnerChange}
                    config={{toolbar:{removeItems:['imageUpload' , 'insertMedia', 'uploadImage' ]}}}                                              
                /> : null}
            </div>
        </div>
    )
}

export default RichTextForm
import React, { useState, useEffect } from 'react';
import BasicView from '../../../../components_v1/Structure/Layouts/BasicView';
import ToolbarMenu from './components/ToolbarMenu';
import queryString from 'query-string' 
import BusinessTimeReport from './views/BusinessTimeReport/BusinessTimeReport';
import BusinessGeneral from './views/BusinessGeneral/BusinessGeneral';
import { connect } from 'react-redux';
import { PERMISSION_MODULES } from '../../../../variables/config';
import BusinessCostSection from '../../../Businesses/BusinessView/views/BusinessCostSection/BusinessCostSection';
import BusinessInboundMailsView from '../../../InboundMails/InboudMailsView/BusinessInboundMailsView';

const menuOptions = [{id:1, label:'Información principal',},{id:2, label:'Time Report'}, {id:3, label:"Costos del negocio"}, {id:5, label:"Correos"}]

const BusinessView = (props) => {

    const {history, match, permissions} = props
    const [view, setView] = useState(1)

    useEffect(() => {
        // Update state from URL args
        const args = queryString.parseUrl(history.location.search)
        if(args && args.query.view) setView(parseInt(args.query.view))
    }, [])

    const onChangeView = (id) => {
        const urlwithargs = `?view=${id}`
        window.history.replaceState(null, null, urlwithargs)
        setView(id)
    }

    let contentView = null

    console.log('hola', permissions)
    // Posicion 5 a la Posicion 36 o de ID 6 a ID 37

    let services_permissions = []
    if(permissions) services_permissions = permissions.slice(5,37)

    let module_permission = null
    if(permissions && permissions.length) module_permission = permissions.find(el => el.permission_module_id === PERMISSION_MODULES.BUSINESSES)
        const businessID = match.params.idbusiness


    if(view == 1) contentView = <BusinessGeneral history={history} match={match} permissions={permissions} services_permissions={services_permissions} module_permission={module_permission}/>
    if(view == 2) contentView = <BusinessTimeReport history={history} match={match} permissions={permissions}  />
    if(view == 3) contentView = <BusinessCostSection id_business={businessID} />
    if(view == 5) contentView = <BusinessInboundMailsView id_business={businessID} />
    return ( 
        <div>
            <BasicView title='Información del negocio' setReturn >
                <>
                    <ToolbarMenu selected={view} menuOptions={menuOptions} onChange={onChangeView} /> 
                    {contentView}
                </>
            </BasicView>
        </div> 
     );
}

const mapStateToProps = state => {
    return{permissions:state.permissions}
}
 
export default connect(mapStateToProps)(BusinessView);
import { useCallback, useEffect, useState } from "react"
import useInboundMail from "../../../hooks/api/useInboundMail"

const useBusinessInboundMailsView = ({id_business}) => {

    const inboudMailManager = useInboundMail()

    const [loading, setLoading] = useState(false)
    const [done, setDone] = useState(false)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState(null)
    const [search_by, setSearchBy] = useState("subject")

    const [tableFilter, setTableFilter] = useState({limit:10,offset:0})

    const [selected, setSelected] = useState(null)

    const onSelect = useCallback((data) => {
        setSelected(data)
    },[])

    const onCloseModal = useCallback(() => {
        setSelected(null)
    },[])
    
    

    const onUpdateTableFilter = async(data) => {
        setTableFilter(data)
    }

    const onGetParams = useCallback((ignorePagination) => {
        let _result = {}
        if(!ignorePagination){
            _result.limit = tableFilter.limit
            _result.offset = tableFilter.offset * tableFilter.limit
            _result.order_by = "id_inbound_mail"
            _result.order = 1
        }

        console.log(id_business)
        let _filter = [
            {field:"business_id", operator:"=", value:id_business}
        ]

        if(search !== "" && search !== null && search !== undefined){
            _filter.push({field:search_by, operator:"LIKE", value: `%${search}%`})
        }

        if(_filter.length) _result.filter = JSON.stringify({AND:_filter})

        return _result

    },[search, tableFilter, search_by, id_business])

    const onChangeSearch = (data) => {
        console.log(data)
        setTableFilter({ limit: 10, offset: 0 })
        setSearch(data)
    }

    const onRequestData = useCallback(async() =>{

        const _params = onGetParams()
        console.log("Parametros", _params)
        try {
            const _request = await inboudMailManager.onGetAll(_params)
            setData(_request.data)
            setTotal(_request.total)
        } catch (error) {
            console.log(error)
        }
        setDone(true)
    },[onGetParams])

    const onRefreshData = useCallback(async() => {
        console.log("Inicializar plataforma")
        setLoading(true)
        try {
            await onRequestData()
        } catch (error) {
            
        }
        setLoading(false)
        

    },[onRequestData])

    const initialize = useCallback(async() => {
        console.log("Inicializar plataforma")
        setLoading(true)
        try {
            await onRequestData()
        } catch (error) {
            
        }
        setLoading(false)

    },[onRequestData])

    useEffect(()=>{
        console.log("Volver a buscar")
        if(done) onRefreshData()
    },[tableFilter, search])

    useEffect(() => {
        initialize()
    },[])

    const filter_data = {
        search_by,
        onChangeSearch,
        setSearchBy
    }

    return {
        loading,
        done,
        data,
        total,
        onUpdateTableFilter,
        filter_data,
        tableFilter,
        onSelect,
        onCloseModal,
        selected
    };
}
 
export default useBusinessInboundMailsView;
import logoSignIn from '../assets/logo.png'
import axios from 'axios';

export const drawerWidth = 110;
export const closedDrawerWidth = 0;

export const imgServerS3 = "https://asiconnect3-public.s3.us-east-2.amazonaws.com/";
export const rfcImgServerS3 = "https://s3.us-east-2.amazonaws.com/asiconnect3-public/business/image";

export const SignIn = {
  logo: logoSignIn,
  spinner: {
    color: '#0c519d'
  }
}

export const SideBar = {
  width: drawerWidth,
  minWidth: closedDrawerWidth,
}

export const TopBar = {
  width: drawerWidth,
  minWidth: closedDrawerWidth,
}

export const Footer = {}

export const Languages = [
  {id:2, code:'MX', language:'spanish'},
  {id:1, code:'US', language:'english'},
];

export const mainServer = {
  instance: axios.create({
    baseURL: "https://api.asiconnect.com.mx/prod",
    headers: {'Content-Type': 'application/json'},
  }),
  credentials: {
    user: 'trionix', //
    password: 'qnJVws4V6amWvZS6f25DJC7cjmLpQzBz'//
  }
}

export const s3Server = {
  instance: axios.create({
    baseURL: "https://s3.us-east-2.amazonaws.com/asiconnect3-public/user/image",
    headers: {'Access-Control-Allow-Origin': '*'},
  })
}

//new

export const CONFIG_SETTINGS = {
  MODE: 'local',
  API_USERNAME: 'trionix',
  API_PASSWORD: 'qnJVws4V6amWvZS6f25DJC7cjmLpQzBz',
  API_BASIC: `Basic ${btoa(process.env.REACT_APP_API_USERNAME + ':' + process.env.REACT_APP_API_PASSWORD)}`,
}

export const private_server = axios.create({
  baseURL: "https://api.asiconnect.com.mx/prod",
  headers: {
      'Content-Type': 'application/json'
  },
})

export const public_server = axios.create({
  baseURL: "https://api.asiconnect.com.mx/prod",
  headers: {
      'Content-Type': 'application/json',
      'Authorization': CONFIG_SETTINGS.API_BASIC
  }
})

export const PERMISSION_TYPES = {
  NO_ACCESS:1,
  READ:2,
  EDIT:3,
  CREATE:4,
  FULL_ACCESS:5
}

export const PERMISSION_MODULES = {
  USERS:1,
  CLIENTS:2,
  BUSINESSES:3,
  SERVICES:4,
  CTRL_TABLES:5,
  STATE_TAXES:6,
  BILLING:7,
  SOCIAL_SECURITIES:8,
  STAMPING:9,
  ACCOUNTING:10,
  TREASURIES:11,
  MONEY_LAUNDERINGS:12,
  PUBLICITY:13,
  OUSOURCING:14,
  TECHNOLOGY:15,
  LEGAL:16,
  AUDIT:17,
  INCORPORATION_OF_COMPANIES:18, //"Constitución de Empresas Socios Nacionales",
  KEY:19,
  MONTHLY_STATEMENT:20,
  SATIC:21,
  FISCAL_ACCOUNTING_UPDATE:22, //"Actualización Fiscal y Contable"
  PHYSICAL_ANNUAL_STATEMENT:23,
  RISK_ANNUAL_STATEMENT:24,
  AC_ANNUAL_STATEMENT:25,
  MORAL_ANUUAL_STATEMENT:26,
  AS_IVIEWER:27,
  BANK_ACCOUNT_OPENING:28,
  IMSS_INSCRIPTION:29,
  MORAL_SAT_INSCRIPTION:30,
  PHYSICAL_SAT_INSCRIPTION:31,
  STATE_REGISTER_INSCRIPTION:32,
  EFIRMA:33,
  FISCAL_DIAGNOSIS:34,
  INFONAVIT:35,
  EXTERNAL_INVESTMENT_REGISTRATION:36,
  CONSULTING:37,
  CERTIFICATE:77,
  CTRL_STATE_TAXES:38,
  CTRL_BILLING:39,
  CTRL_SOCIAL_SECURITIES:40,
  CTRL_STAMPING:41,
  CTRL_ACCOUNTING:42,
  CTRL_MONTHLY_STATEMENT:52,
  CTRL_PHYSICAL_ANNUAL_STATEMENT:55,
  CTRL_AC_ANNUAL_STATEMENT:57,
  CTRL_MORAL_ANNUAL_STATEMENT:58,
  CTRL_RISK_ANNUAL_STATEMENT:59,
  CTRL_EFIRMA:65,
  CTRL_CERTIFICATE:76,
  //"Tabla de Control de Administración de la tesorería":43,"Tabla de Control de Prevención en Lavado de Dinero":44,"Tabla de Control de Publicidad":45,"Tabla de Control de Outsourcing":46,"Tabla de Control de Desarrollo tecnológico":47,"Tabla de Control de Servicios legales":48,"Tabla de Control de Auditoría":49,"Tabla de Control de Constitución de Empresas Socios Nacionales":50,"Tabla de Control de Llave en Mano":51,"Tabla de Control de Declaraciones mensuales (impuestos e informativas)":52,"Tabla de Control de SATIC o SIROC":53,"Tabla de Control de Actualización Fiscal y Contable":54,"Tabla de Control de Declaración anual físicas ":55,"Tabla de Control de Declaración anual Riesgo de Trabajo":56,"Tabla de Control de Declaración anual A.C.":57,"Tabla de Control de Declaración anual moral":58,"Tabla de Control de AS iViewer":59,"Tabla de Control de Apertura de Cuenta Bancaria":60,"Tabla de Control de Inscripción al IMSS":61,"Tabla de Control de Inscripción al SAT Persona Moral":62,"Tabla de Control de Inscripción al SAT Persona Física":63,"Tabla de Control de Inscripción registro estatal de contribuyentes":64,"Tabla de Control de Trámite o Renovación E.firma":65,"Tabla de Control de Diagnóstico Fiscal":66,"Tabla de Control de Infonavit":67,"Tabla de Control de Registro en inversión Extranjera":68,"Tabla de Control de Consultoría":69,"Reporte de Horas":70,"Analíticos de Reporte de Horas":71
  CONTRACTS:72
}

export const SERVICE_PERMISSION_DICTIONARY = { // SERVICE_ID : PERMISSION_MODULE_ID
  '1': 6,'2': 7,'3': 8,'4': 9,'5': 10,'6': 11,'7': 12,'8': 13,'9': 14,'10': 15,'11': 16,'12': 17,'13': 18,'14': 19,'15': 20,'16': 21,'17': 22,'18': 23,'19': 24,'20': 25,'21': 26,'22': 27,'23': 28,'24': 29,'25': 30,'26': 31,'27': 32,'28': 33,'29': 34,'30': 35,'31': 36,'32': 37, '33': 77
}

export const MATRIX_SERVICE_PERMISSION = [
  {service_id:1, permission_module_id:6},
  {service_id:2, permission_module_id:7},
  {service_id:3, permission_module_id:8},
  {service_id:4, permission_module_id:9},
  {service_id:5, permission_module_id:10},
  {service_id:6, permission_module_id:11},
  {service_id:7, permission_module_id:12},
  {service_id:8, permission_module_id:13},
  {service_id:9, permission_module_id:14},
  {service_id:10, permission_module_id:15},
  {service_id:11, permission_module_id:16},
  {service_id:12, permission_module_id:17},
  {service_id:13, permission_module_id:18},
  {service_id:14, permission_module_id:19},
  {service_id:15, permission_module_id:20},
  {service_id:16, permission_module_id:21},
  {service_id:17, permission_module_id:22},
  {service_id:18, permission_module_id:23},
  {service_id:19, permission_module_id:24},
  {service_id:20, permission_module_id:25},
  {service_id:21, permission_module_id:26},
  {service_id:22, permission_module_id:27},
  {service_id:23, permission_module_id:28},
  {service_id:24, permission_module_id:29},
  {service_id:25, permission_module_id:30},
  {service_id:26, permission_module_id:31},
  {service_id:27, permission_module_id:32},
  {service_id:28, permission_module_id:33},
  {service_id:29, permission_module_id:34},
  {service_id:30, permission_module_id:35},
  {service_id:31, permission_module_id:36},
  {service_id:32, permission_module_id:37},
]

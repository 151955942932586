import React from 'react';
import useBusinessInboundMailsView from './useBusinessInboundMailsView';
import InboundMailsTable from '../../../components_v1/inboundmails/InboundMailsTable';
import InboundMailModal from '../../../modals/inboundmails/InboudMailModal';

const BusinessInboundMailsView = ({
    id_business
}) => {

    const {
        loading, 
        data,
        filter_data,
        tableFilter,
        total,
        selected,
        onSelect,
        onCloseModal
    } = useBusinessInboundMailsView({id_business})

    
    console.log(selected)
    return ( 
        <div style={{marginTop:8}}>

            <InboundMailsTable
                hide_month={true}
                hide_search={true}
                loading={loading}
                data={data}
                filter_data={filter_data}
                tableFilter={tableFilter}
                total={total}
                onSelect={onSelect}
            />
            <div style={{height:60}} />
            <InboundMailModal 
                open={Boolean(selected)}
                onClose={onCloseModal}
                id_inbound_mail={selected?.id_inbound_mail}
            
            />
        </div>
     );
}
 
export default BusinessInboundMailsView;
import React, { useState, useEffect } from 'react';
import BasicView from '../../../components_v1/Structure/Layouts/BasicView';
import OverviewTimeReport from './components/OverviewTimeReport';
import { Grid } from '@material-ui/core';
import { request_download_document, request_timereport, request_update_timereport, request_delete_document } from './requests';
import { onGetErrorMessage, onGetUserID } from '../../../shared/utility';
import TimeReportForm from './components/TimeReportForm';
import DocumentForm from './components/DocumentForm';
import DocumentModal from './modals/DocumentModal';

const TimeReportView = (props) => {

    const {match, history, data} = props
    const timereportID = match.params.id

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [timereport, setTimereport] = useState(null)
    const [documentmodal, setDocumentmodal] = useState(false)

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _timereport = await request_timereport(timereportID)
            setTimereport(_timereport)
        } catch (error) {
            
        } setLoading(false)}
        initModule()
    },[])

    const onChangeState = async(data) => {
        const data2send = {state_id:data.value}
        try {
            setSending(true)
            const _timereport = await request_update_timereport(timereportID, data2send)
            setTimereport(_timereport)
            history.goBack()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setSending(false)

    }

    const onUpdateTimeReport = (data) => {
        setTimereport(data)
        setSuccess('Información actualizada')
        history.goBack()
    }

    const onDownloadDocument = async() => {try {
        await request_download_document(timereportID)
    } catch (error) {
        console.log(error)
        setError(onGetErrorMessage(error))
    }}

    const onDeleteDocument = async() => {try {
        setSending(true)
        const _timereport = await request_delete_document(timereportID)
        setTimereport(_timereport)
        setSuccess('Archivo eliminado exitosamente')
    } catch (error) {
        console.log(error)
        setError(onGetErrorMessage(error))
    } setSending(false)}


    const userID = onGetUserID()
    const isSupervisor = timereport ? (timereport.supervisor_id === userID) : false

    return ( 
        <BasicView loading={loading} title='Detalles de Time Report' sending={sending} setReturn
        
        success={success} onCloseSuccess={() => setSuccess(null)}
        error={error} onCloseError={() => setError(null)}>
            <div>
                <DocumentModal id={timereportID} open={documentmodal} onClose={() => setDocumentmodal(false)} onUpdateData={(data) => setTimereport(data)}/>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <OverviewTimeReport data={timereport} onChangeState={onChangeState} isSupervisor={isSupervisor}/>
                            </Grid>
                            <Grid item xs={12}>
                                <DocumentForm file={timereport ? timereport.evidence : null} onUploadDocument={() => setDocumentmodal(true)}
                                onDownloadDocument={onDownloadDocument} onDeleteDocument={onDeleteDocument}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TimeReportForm userID={userID} id={timereportID} timereport={timereport} onUpdateData={onUpdateTimeReport}
                        onUpdateError={(e)=>setError(e)}  />
                    </Grid>
                </Grid>
            </div>
        </BasicView>
     );
}
 
export default TimeReportView;